import axios from "axios";
import { toast } from "react-toastify";

const urlApi = process.env.REACT_APP_API_URL;

const getRequestConfig = (tokenResponse) => {
  const token = tokenResponse || localStorage.getItem("token");
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

const handleErrorResponse = (error, message) => {
  console.error(message, error);
  toast.error(message);
  return null;
};

export const getProyectos = async (id_proyecto, tokenResponse = null) => {
  const params = id_proyecto ? `/${id_proyecto}` : "";
  try {
    const response = await axios.get(
      `${urlApi}/proyectos${params}`,
      getRequestConfig(tokenResponse)
    );
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al traer los proyectos");
    }
    return error;
  }
};

export const getUsuariosProyectos = async (id_user, tokenResponse = null) => {
  try {
    const response = await axios.get(
      `${urlApi}/usuarios-proyectos`,
      getRequestConfig(tokenResponse)
    );
    return id_user
      ? response.data.filter(
          (conexion) => conexion.id_user === parseInt(id_user)
        )
      : response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(
        error,
        "Error al traer las conexiones entre los proyectos y los usuarios"
      );
    }
    return error;
  }
};

export const getClients = async (
  id_user = null,
  id_cliente = null,
  tokenResponse = null
) => {
  const params = id_cliente ? `/${id_cliente}` : "";
  try {
    const response = await axios.get(
      `${urlApi}/clientes${params}`,
      getRequestConfig(tokenResponse)
    );
    return id_user
      ? response.data.filter((client) => client.id_user === parseInt(id_user))
      : response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al traer los clientes");
    }
    return error;
  }
};

export const getComedores = async (
  id_proyecto = null,
  id_comedor,
  tokenResponse = null
) => {
  const params = id_comedor ? `/${id_comedor}` : "";
  try {
    const response = await axios.get(
      `${urlApi}/comedores${params}`,
      getRequestConfig(tokenResponse)
    );
    return id_proyecto
      ? response.data.filter(
          (comedor) => comedor.id_proyecto === parseInt(id_proyecto)
        )
      : response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al traer los comedores");
    }
    return error;
  }
};

export const getLectores = async (
  id_proyecto = null,
  id_comedor = null,
  id_lector = null,
  tokenResponse = null
) => {
  const params = id_lector ? `/${id_lector}` : "";
  try {
    const response = await axios.get(
      `${urlApi}/lectores${params}`,
      getRequestConfig(tokenResponse)
    );
    let filterProject = response.data;
    if (id_proyecto) {
      filterProject = filterProject.filter(
        (lector) => lector.id_proyecto === parseInt(id_proyecto)
      );
    }
    if (id_comedor) {
      filterProject = filterProject.filter(
        (lector) => lector.id_comedor === parseInt(id_comedor)
      );
    }
    return filterProject.length
      ? filterProject
      : "El comedor seleccionado no tiene lectores";
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al traer los lectores");
    }
    return error;
  }
};

export const getComensales = async (
  id_proyecto = null,
  num_doc = null,
  id_comensal = null,
  tokenResponse = null
) => {
  const params = id_comensal ? `/${id_comensal}` : "";
  try {
    const response = await axios.get(
      `${urlApi}/comensales${params}`,
      getRequestConfig(tokenResponse)
    );
    let filterComensal = response.data;
    if (id_proyecto) {
      filterComensal = filterComensal.filter(
        (comensal) => comensal.id_proyecto === parseInt(id_proyecto)
      );
    }
    if (num_doc) {
      filterComensal = filterComensal.filter(
        (comensal) => comensal.numero_documento === num_doc
      );
    }
    return filterComensal;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al traer los comensales");
    }
    return error;
  }
};

export const getTurno = async (
  id_proyecto = null,
  id_cliente = null,
  id_turno_total = null,
  id_comedor = null,
  id_comensal = null,
  id_tipo_entrada = null,
  valor_servicio = null,
  id_turno = null,
  tokenResponse = null
) => {
  const params = id_turno ? `/${id_turno}` : "";
  try {
    const response = await axios.get(
      `${urlApi}/turnos${params}`,
      getRequestConfig(tokenResponse)
    );
    let filterTurno = response.data;
    if (id_proyecto) {
      filterTurno = filterTurno.filter(
        (turno) => turno.id_proyecto === parseInt(id_proyecto)
      );
    }
    if (id_cliente) {
      filterTurno = filterTurno.filter(
        (turno) => turno.id_cliente === parseInt(id_cliente)
      );
    }
    if (id_turno_total) {
      filterTurno = filterTurno.filter(
        (turno) => turno.id_turno_total === parseInt(id_turno_total)
      );
    }
    if (id_comedor) {
      filterTurno = filterTurno.filter(
        (turno) => turno.id_comedor === parseInt(id_comedor)
      );
    }
    if (id_comensal) {
      filterTurno = filterTurno.filter(
        (turno) => turno.id_comensal === parseInt(id_comensal)
      );
    }
    if (id_tipo_entrada) {
      filterTurno = filterTurno.filter(
        (turno) => turno.id_tipo_entrada === parseInt(id_tipo_entrada)
      );
    }
    if (valor_servicio) {
      filterTurno = filterTurno.filter(
        (turno) => turno.valor_servicio === parseInt(valor_servicio)
      );
    }
    return filterTurno;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al traer los turnos");
    }
    return error;
  }
};

export const getTurnoTotal = async (
  id_proyecto = null,
  id_comedor = null,
  id_tipo_servicio = null,
  id_lector = null,
  cantidad_comensales = null,
  cerrado = null,
  id_turno_total = null,
  tokenResponse = null
) => {
  const params = id_turno_total ? `/${id_turno_total}` : "";
  try {
    const response = await axios.get(
      `${urlApi}/turnos-totales${params}`,
      getRequestConfig(tokenResponse)
    );
    let filterTurnoTotal = response.data;
    if (id_proyecto) {
      filterTurnoTotal = filterTurnoTotal.filter(
        (turnoTotal) => turnoTotal.id_proyecto === parseInt(id_proyecto)
      );
    }
    if (id_comedor) {
      filterTurnoTotal = filterTurnoTotal.filter(
        (turnoTotal) => turnoTotal.id_comedor === parseInt(id_comedor)
      );
    }
    if (id_tipo_servicio) {
      filterTurnoTotal = filterTurnoTotal.filter(
        (turnoTotal) =>
          turnoTotal.id_tipo_servicio === parseInt(id_tipo_servicio)
      );
    }
    if (id_lector) {
      filterTurnoTotal = filterTurnoTotal.filter(
        (turnoTotal) => turnoTotal.id_lector === parseInt(id_lector)
      );
    }
    if (cantidad_comensales) {
      filterTurnoTotal = filterTurnoTotal.filter(
        (turnoTotal) =>
          turnoTotal.cantidad_comensales === parseInt(cantidad_comensales)
      );
    }
    if (cerrado !== null && cerrado !== undefined) {
      filterTurnoTotal = filterTurnoTotal.filter(
        (turnoTotal) => turnoTotal.cerrado === parseInt(cerrado)
      );
    }
    return filterTurnoTotal;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al traer los turnos totales");
    }
    return error;
  }
};

export const postTurno = async (
  id_proyecto = null,
  id_cliente = null,
  id_turno_total = null,
  id_comedor = null,
  id_comensal = null,
  id_tipo_entrada = null,
  valor_servicio = null,
  id_turno = null,
  tokenResponse = null
) => {
  const params = id_turno ? `/${id_turno}` : "";

  const data = {
    id_proyecto,
    id_cliente,
    id_turno_total,
    id_comedor,
    id_comensal,
    id_tipo_entrada,
    precio_venta: valor_servicio,
  };

  try {
    const response = await axios.post(
      `${urlApi}/turnos${params}`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al publicar los comensales");
    }
    return error;
  }
};

export const postTurnoTotal = async (
  id_proyecto = null,
  id_comedor = null,
  id_tipo_servicio = null,
  id_lector = null,
  cantidad_comensales = null,
  cerrado = null,
  id_turno_total = null,
  tokenResponse = null
) => {
  const data = {
    id_proyecto,
    id_comedor,
    id_tipo_servicio,
    id_lector,
    cantidad_comensales,
    cerrado,
  };

  try {
    let response;
    if (id_turno_total) {
      response = await axios.put(
        `${urlApi}/turnos-totales/${id_turno_total}`,
        data,
        getRequestConfig(tokenResponse)
      );
    } else {
      response = await axios.post(
        `${urlApi}/turnos-totales`,
        data,
        getRequestConfig(tokenResponse)
      );
    }
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al publicar el turno total");
    }
    return error;
  }
};

export const postComensales = async (data, tokenResponse = null) => {
  try {
    const response = await axios.post(
      `${urlApi}/comensales`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al publicar los comensales");
    }
    return error;
  }
};

export const postProyectos = async (data, tokenResponse = null) => {
  try {
    const response = await axios.post(
      `${urlApi}/proyectos`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al publicar los proyectos");
    }
    return error;
  }
};

export const postClientes = async (data, tokenResponse = null) => {
  try {
    const response = await axios.post(
      `${urlApi}/clientes`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al publicar los clientes");
    }
    return error;
  }
};

export const postComedores = async (data, tokenResponse = null) => {
  try {
    const response = await axios.post(
      `${urlApi}/comedores`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al publicar los comedores");
    }
    return error;
  }
};

export const postLectores = async (data, tokenResponse = null) => {
  try {
    const response = await axios.post(
      `${urlApi}/lectores`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al publicar los lectores");
    }
    return error;
  }
};

export const putLectores = async (
  data,
  id_lector = null,
  tokenResponse = null
) => {
  try {
    const response = await axios.put(
      `${urlApi}/lectores/${id_lector}`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al editar los lectores");
    }
    return error;
  }
};

export const putComedores = async (
  data,
  id_comedor = null,
  tokenResponse = null
) => {
  try {
    const response = await axios.put(
      `${urlApi}/comedores/${id_comedor}`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al editar los comedores");
    }
    return error;
  }
};

export const putClientes = async (
  data,
  id_cliente = null,
  tokenResponse = null
) => {
  try {
    const response = await axios.put(
      `${urlApi}/clientes/${id_cliente}`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al editar los clientes");
    }
    return error;
  }
};

export const putProyectos = async (
  data,
  id_proyecto = null,
  tokenResponse = null
) => {
  try {
    const response = await axios.put(
      `${urlApi}/proyectos/${id_proyecto}`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al editar los proyectos");
    }
    return error;
  }
};

export const putComensales = async (
  data,
  id_comensal = null,
  tokenResponse = null
) => {
  try {
    const response = await axios.put(
      `${urlApi}/comensales/${id_comensal}`,
      data,
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al editar los comensales");
    }
    return error;
  }
};

export const deleteTurnoTotal = async (
  id_turno_total = null,
  tokenResponse = null
) => {
  if (!id_turno_total) {
    return handleErrorResponse(
      null,
      "ID de turno total es requerido para eliminar"
    );
  }

  try {
    const response = await axios.delete(
      `${urlApi}/turnos-totales/${id_turno_total}`,
      getRequestConfig(tokenResponse)
    );
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al eliminar el turno total");
    }
    return error;
  }
};

export const deleteCliente = async (
  id_cliente = null,
  tokenResponse = null
) => {
  if (!id_cliente) {
    return handleErrorResponse(
      null,
      "ID de cliente es requerido para eliminar"
    );
  }

  try {
    const response = await axios.delete(
      `${urlApi}/clientes/${id_cliente}`,
      getRequestConfig(tokenResponse)
    );
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al eliminar el cliente");
    }
    return error;
  }
};

export const deleteComedor = async (
  id_comedor = null,
  tokenResponse = null
) => {
  if (!id_comedor) {
    return handleErrorResponse(
      null,
      "ID de comedor es requerido para eliminar"
    );
  }

  try {
    const response = await axios.delete(
      `${urlApi}/comedores/${id_comedor}`,
      getRequestConfig(tokenResponse)
    );
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al eliminar el comedor");
    }
    return error;
  }
};

export const deleteLector = async (id_lector = null, tokenResponse = null) => {
  if (!id_lector) {
    return handleErrorResponse(null, "ID de lector es requerido para eliminar");
  }

  try {
    const response = await axios.delete(
      `${urlApi}/lectores/${id_lector}`,
      getRequestConfig(tokenResponse)
    );
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al eliminar el lector");
    }
    return error;
  }
};

export const deleteProyecto = async (
  id_proyecto = null,
  tokenResponse = null
) => {
  if (!id_proyecto) {
    return handleErrorResponse(
      null,
      "ID de proyecto es requerido para eliminar"
    );
  }

  try {
    const response = await axios.delete(
      `${urlApi}/proyectos/${id_proyecto}`,
      getRequestConfig(tokenResponse)
    );
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al eliminar el proyecto");
    }
    return error;
  }
};

export const deleteComensal = async (
  id_comensal = null,
  tokenResponse = null
) => {
  if (!id_comensal) {
    return handleErrorResponse(
      null,
      "ID de comensal es requerido para eliminar"
    );
  }

  try {
    const response = await axios.delete(
      `${urlApi}/comensales/${id_comensal}`,
      getRequestConfig(tokenResponse)
    );
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al eliminar el comensal");
    }
    return error;
  }
};

export const Logout = async (tokenResponse) => {
  try {
    const response = await axios.post(
      `${urlApi}/logout`,
      "bye",
      getRequestConfig(tokenResponse)
    );
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al cerrar sesión");
    }
    return error;
  }
};

import React, { useState } from "react";
import "./css/Header.css";
import { MdBuild, MdFlashOn, MdAssessment } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (item) => {
    setIsHovered(true);
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredItem(null);
  };

  return (
    <header
      id="header"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={handleMouseLeave}
    >
      <ul>
        <li onMouseEnter={() => handleMouseEnter("mantenimientos")}>
          {isHovered ? "Mantenimientos" : <MdBuild size={30} />}
          {isHovered && hoveredItem === "mantenimientos" && (
            <nav className="dropdown-menu">
              <ul>
                <li onClick={() => navigate("/cargar-clientes")}>Clientes</li>
                <li onClick={() => navigate("/cargar-proyectos")}>Proyectos</li>
                <li onClick={() => navigate("/cargar-comedores")}>Comedores</li>
                {/* <li onClick={() => navigate("/cargar-clientes")}>Comedor Tipo de Servicios</li> */}
                <li onClick={() => navigate("/cargar-lectoras")}>Lectoras</li>
                <li onClick={() => navigate("/cargar-comensal")}>Comensales</li>
                {/* <li onClick={() => navigate("/cargar-clientes")}>Generales</li> */}
              </ul>
            </nav>
          )}
        </li>
        <li onMouseEnter={() => handleMouseEnter("procesos")}>
          {isHovered ? "Procesos" : <MdFlashOn size={30} />}
          {isHovered && hoveredItem === "procesos" && (
            <nav
              className="dropdown-menu"
              style={{ top: "130px", left: "100px" }}
            >
              <ul>
                <li onClick={() => navigate("/gestionar-turnos")}>
                  Gestionar Turnos
                </li>
                <li onClick={() => navigate("/cargar-comensales")}>
                  Cargar Comensales
                </li>
              </ul>
            </nav>
          )}
        </li>
        <li onMouseEnter={() => handleMouseEnter("reportes")}>
          {isHovered ? "Reportes" : <MdAssessment size={30} />}
          {isHovered && hoveredItem === "reportes" && (
            <nav
              className="dropdown-menu"
              style={{ top: "200px", left: "100px" }}
            >
              <ul>
                <li onClick={() => navigate("/generar-reporte-turno")}>
                  Generar Reporte Atencion Por Comedor
                </li>
                <li onClick={() => navigate("/generar-reporte-comensales")}>
                  Generar Reporte Comensales Por Comedor
                </li>
              </ul>
            </nav>
          )}
        </li>
      </ul>
    </header>
  );
};

export default Header;

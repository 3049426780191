import Header from "../components/Header";

const Home = () => {
  return (
    <section id="home">
      <Header />
    </section>
  );
};

export default Home;

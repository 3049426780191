import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./css/Modal.css";
import { getLectores, postTurnoTotal } from "../config/peticionesConToken";

const ModalNew = ({
  cliente,
  proyecto,
  comedores,
  tipoServicio,
  isOpen,
  onClose,
}) => {
  const today = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today);
  const [comedor, setComedor] = useState(null);
  const [servicio, setServicio] = useState(null);
  const [lector, setLector] = useState(null);
  const [lectores, setLectores] = useState(null);

  const handleSerComedor = (event) => {
    getLectores(proyecto.id, parseInt(event), null)
      .then((res) => {
        setComedor(parseInt(event));
        setLectores(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (!isOpen) return null;

  const crearTurno = () => {
    postTurnoTotal(
      proyecto.id,
      comedor || null,
      parseInt(servicio) || null,
      parseInt(lector) || null,
      0,
      false,
      null,
      localStorage.getItem("token")
    )
      .then((res) => {
        toast.success("Turno creado con exito", {
          position: "bottom-right",
          autoClose: 1000,
          onClose: () => onClose(true),
        });
      })
      .catch((err) => {
        toast.error("Error al crear el turno", {
          position: "bottom-right",
          autoClose: 1000,
        });
        console.error(err);
      });
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <ToastContainer />
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Turno</h2>
        </div>
        <form className="modal-form">
          <label id="proyecto" value={proyecto} required>
            Proyecto
            <input disabled type="text" placeholder={proyecto.nombre} />
          </label>
          <label
            id="comedor"
            value={comedor}
            onChange={(e) => handleSerComedor(e.target.value)}
            required
          >
            Comedor
            <select>
              <option selected disabled value="">
                Selecciona un comedor
              </option>
              {comedores &&
                comedores.map((comedor, index) => (
                  <option key={index} value={comedor.id}>
                    {comedor.nombre}
                  </option>
                ))}
            </select>
          </label>
          <label
            id="servicio"
            value={servicio}
            onChange={(e) => setServicio(e.target.value)}
            required
          >
            Tipo Servicio
            <select>
              <option selected disabled value="">
                Selecciona un tipo de servicio
              </option>
              {tipoServicio &&
                tipoServicio.map((servicio, index) => (
                  <option key={index} value={servicio.id}>
                    {servicio.nombre}
                  </option>
                ))}
            </select>
          </label>
          {console.log(lectores && lectores.length)}
          <label
            id="lectora"
            value={lector}
            onChange={(e) => setLector(e.target.value)}
            required
          >
            Lectora
            <select>
              <option selected disabled value="">
                Selecciona un lector
              </option>
              {Array.isArray(lectores) && lectores.length > 0 ? (
                lectores.map((lector, index) => (
                  <option key={index} value={lector.id}>
                    {lector.nombre}
                  </option>
                ))
              ) : (
                <option disabled value="">
                  No tiene lectores este comedor
                </option>
              )}
            </select>
          </label>
          <label>
            Fecha
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </label>
          <div className="modal-actions">
            <button type="button" onClick={crearTurno} className="save-button">
              Guardar
            </button>
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalNew;

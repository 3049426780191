import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import {
  getClients,
  getComedores,
  postComensales,
} from "../config/peticionesConToken";
import Header from "../components/Header";
import "../components/css/CargarComensales.css";
import Loader from "../components/Loader";

const CargarComensales = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"));
  const [file, setFile] = useState(null);
  const [comedores, setComedores] = useState(null);
  const [clientes, setClientes] = useState(null);
  const [additionalData, setAdditionalData] = useState({
    id_cliente: "",
    id_proyecto: selectedProject ? selectedProject.id : "",
    id_comedor: "",
  });
  const [openLoader, setOpenLoader] = useState(false);

  useEffect(() => {
    if (selectedProject) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      const [comedoresBd, clientesBd] = await Promise.all([
        getComedores(selectedProject.id, null, token),
        getClients(currentUser.id, null, token),
      ]);
      setComedores(comedoresBd);
      setClientes(clientesBd.length > 0 ? clientesBd : [clientesBd]);
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    const value =
      e.target.value.trim() === "" ? undefined : parseInt(e.target.value);
    setAdditionalData({ ...additionalData, [e.target.name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (file) {
      setOpenLoader(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        if (data.length === 0) {
          const headers = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
          const emptyData = headers.reduce((acc, curr) => {
            acc[curr] = null;
            return acc;
          }, {});

          await processBatch([emptyData]);
        } else {
          const batchSize = 100;
          const batches = [];
          for (let i = 0; i < data.length; i += batchSize) {
            const batch = data.slice(i, i + batchSize);
            batches.push(batch);
          }
          await processBatches(batches);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const processBatch = async (batch) => {
    const formattedBatch = batch.map((comensal) => ({
      ...comensal,
      ...additionalData,
    }));
    await postComensales(formattedBatch, null, token);
    setOpenLoader(false);
  };

  const processBatches = async (batches) => {
    for (const batch of batches) {
      await processBatch(batch);
    }
    toast.success("Comensales agregados exitosamente", {
      position: "bottom-right",
      autoClose: 2000,
      onClose: () => window.location.reload(),
    });
  };

  return (
    <section id="cargar_comensales">
      <Loader isVisible={openLoader} />
      <Header />
      <ToastContainer />
      <div className="cargar_comensales">
        <div className="toolbar">
          <button className="toolbar-button" onClick={() => navigate("/home")}>
            <MdLogout size={24} />
            Cerrar
          </button>
        </div>
        <div className="content" style={{ height: "max-content" }}>
          <form onSubmit={handleSubmit}>
            <div className="container_selects">
              <label htmlFor="id_proyecto">
                <p>Proyecto</p>
                <input
                  type="text"
                  name="id_proyecto"
                  id="id_proyecto"
                  value={selectedProject.nombre}
                  placeholder={selectedProject.nombre}
                  disabled
                />
              </label>
              <label htmlFor="id_cliente">
                <p>Cliente</p>
                <select
                  id="id_cliente"
                  name="id_cliente"
                  value={additionalData.id_cliente}
                  onChange={handleInputChange}
                  required
                >
                  <option selected disabled value="">
                    Selecciona un cliente
                  </option>
                  {clientes &&
                    clientes.map((cliente, index) => (
                      <option key={index} value={cliente.id}>
                        {cliente.nombre_sociedad}
                      </option>
                    ))}
                </select>
              </label>
              <label htmlFor="id_comedor">
                <p>Comedor</p>
                <select
                  id="id_comedor"
                  name="id_comedor"
                  value={additionalData.id_comedor}
                  onChange={handleInputChange}
                  required
                >
                  <option selected disabled value="">
                    Selecciona un comedor
                  </option>
                  {comedores &&
                    comedores.map((comedor, index) => (
                      <option key={index} value={comedor.id}>
                        {comedor.nombre}
                      </option>
                    ))}
                </select>
              </label>
            </div>
            <label htmlFor="fileExcel" className="file-upload">
              <p>Archivo Excel A Subir</p>
              <input
                type="file"
                id="fileExcel"
                onChange={handleFileChange}
                accept=".xlsx, .xls"
              />
            </label>
            <button type="submit">Subir Comensales</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CargarComensales;

import React from "react";
import "./css/Loader.css";

const Loader = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="containerLoader">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;

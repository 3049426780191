import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import "./App.css";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import CargarComensales from "./pages/CargarComensales";
import ReporteAtencionComedor from "./pages/ReporteAtencionComedor";
import ReporteComensalesComedor from "./pages/ReporteComensalesComedor";
import CargarClientes from "./pages/CargarClientes";
import CargarProyectos from "./pages/CargarProyectos";
import CargarComedores from "./pages/CargarComedores";
import CargarLectoras from "./pages/CargarLectoras";
import CargarComensales1por1 from "./pages/CargarComensales1por1";

function App() {
  if (isMobile || isTablet) {
    return (
      <div className="App">
        <h1>
          Lo sentimos, esta aplicación no es compatible con dispositivos móviles
          y tabletas.
        </h1>
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/gestionar-turnos" element={<Dashboard />} />
          <Route path="/cargar-comensales" element={<CargarComensales />} />
          <Route path="/cargar-clientes" element={<CargarClientes />} />
          <Route path="/cargar-proyectos" element={<CargarProyectos />} />
          <Route path="/cargar-comedores" element={<CargarComedores />} />
          <Route path="/cargar-lectoras" element={<CargarLectoras />} />
          <Route path="/cargar-comensal" element={<CargarComensales1por1 />} />
          <Route
            path="/generar-reporte-turno"
            element={<ReporteAtencionComedor />}
          />
          <Route
            path="/generar-reporte-comensales"
            element={<ReporteComensalesComedor />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../components/css/Login.css";
import { getLogin } from "../config/peticionesSinTokens";
import {
  getProyectos,
  getUsuariosProyectos,
} from "../config/peticionesConToken";
import Loader from "../components/Loader";

const Login = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState(null);
  const [openLoader, setOpenLoader] = useState(false);
  const navigate = useNavigate();

  const handleClickSelectProyect = async (event) => {
    event.preventDefault();
    setOpenLoader(true);

    await getLogin(user, password)
      .then(async (response) => {
        if (response && response.status === 200 && response.data) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));

          const responseClients = await getUsuariosProyectos(
            response.data.user.id,
            response.data.token
          );

          if (responseClients) {
            localStorage.setItem("clients", JSON.stringify(responseClients));

            const responseProyects = await getProyectos(
              responseClients[0].id_proyecto,
              response.data.token
            );

            localStorage.setItem(
              "proyectosUser",
              JSON.stringify(responseProyects)
            );

            if (responseProyects && responseProyects.lenght > 0) {
              setProjects(responseProyects);
            } else {
              setProjects([responseProyects]);
            }
          }
        } else if (response.status >= 400 && response.data) {
          toast.error(response.data.error || response.data.message, {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setOpenLoader(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedProject = projects.find(
      (proyect) => proyect.id === parseInt(project)
    );
    if (selectedProject) {
      localStorage.setItem("selectedProject", JSON.stringify(selectedProject));

      toast.success("Inicio de sesión correcto, redireccionando...", {
        position: "bottom-right",
        autoClose: 1000,
      });
      navigate("/gestionar-turnos");
    }
  };

  return (
    <div className="login-container">
      <Loader isVisible={openLoader} />
      <ToastContainer />
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Iniciar Sesión</h2>

        <div className="input-group">
          <label htmlFor="user">Usuario</label>
          <input
            type="text"
            id="user"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        {projects && (
          <div className="input-group">
            <label htmlFor="project">Proyecto</label>
            <select
              id="project"
              value={project}
              onChange={(e) => setProject(e.target.value)}
              required
            >
              <option selected disabled value="">
                Selecciona un proyecto
              </option>
              {projects &&
                projects.map((project, index) => (
                  <option key={index} value={project.id}>
                    {project.nombre}
                  </option>
                ))}
            </select>
          </div>
        )}

        <div style={{ textAlign: "center" }} className="buttons">
          {projects ? (
            <button type="submit" className="submit-button">
              <FaCheckCircle size={24} /> Ingresar
            </button>
          ) : (
            <button
              onClick={(e) => handleClickSelectProyect(e)}
              className="submit-button"
            >
              <FaCheckCircle size={24} /> Seleccionar proyecto
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;

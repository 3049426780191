import axios from "axios";
import { toast } from "react-toastify";

const urlApi = process.env.REACT_APP_API_URL;

const handleErrorResponse = (error, defaultMessage) => {
  console.error(defaultMessage, error);
  toast.error(defaultMessage);
  return null;
};

export const getLogin = async (name, password) => {
  if (!name || !password) {
    const message = !name
      ? "No proporcionaste un nombre de usuario"
      : "No proporcionaste una contraseña";
    toast.warn(message);
    return null;
  }

  try {
    const response = await axios.post(`${urlApi}/login`, {
      nombre: name,
      password,
    });
    return response;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error durante el inicio de sesión");
    }
    return error;
  }
};

export const getTipoServicio = async (id_tipo_servicio) => {
  const params = id_tipo_servicio ? `/${id_tipo_servicio}` : "";
  try {
    const response = await axios.get(`${urlApi}/servicios${params}`);
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(
        error,
        "Error al traer los tipos de servicios"
      );
    }
    return error;
  }
};

export const getTipoDocumento = async (id_tipo_documento) => {
  const params = id_tipo_documento ? `/${id_tipo_documento}` : "";
  try {
    const response = await axios.get(`${urlApi}/documentos${params}`);
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(
        error,
        "Error al traer los tipos de documentos"
      );
    }
    return error;
  }
};

export const getTipoCargo = async (id_tipo_cargo) => {
  const params = id_tipo_cargo ? `/${id_tipo_cargo}` : "";
  try {
    const response = await axios.get(`${urlApi}/tipos-cargos${params}`);
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(
        error,
        "Error al traer los tipos de cargos"
      );
    }
    return error;
  }
};

export const getTipoEntrada = async (id_tipo_entrada) => {
  const params = id_tipo_entrada ? `/${id_tipo_entrada}` : "";
  try {
    const response = await axios.get(`${urlApi}/tipos-entradas${params}`);
    return response.data;
  } catch (error) {
    if (error.response.status >= 500) {
      return handleErrorResponse(error, "Error al traer los tipos de entrada");
    }
    return error;
  }
};

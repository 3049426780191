import React, { useEffect, useState } from "react";
import "./css/ModalPostMantenimientos.css";
import { toast } from "react-toastify";

const ModalPutMantenimientos = ({
  put,
  data,
  isOpen,
  onClose,
  dataSelected,
}) => {
  // PROYECTO
  const [nombreProyecto, setNombreProyecto] = useState("");
  const [paisProyecto, setPaisProyecto] = useState("");
  const [provinciaProyecto, setProvinciaProyecto] = useState("");
  const [distritoProyecto, setDistritoProyecto] = useState("");

  // CLIENTE
  const [nombreSociedadCliente, setNombreSociedadCliente] = useState("");
  const [primerNombreCliente, setPrimerNombreCliente] = useState("");
  const [segundoNombreCliente, setSegundoNombreCliente] = useState("");
  const [primerApellidoCliente, setPrimerApellidoCliente] = useState("");
  const [segundoApellidoCliente, setSegundoApellidoCliente] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState(null);
  const [numeroDocumentoCliente, setNumeroDocumentoCliente] = useState(null);

  // COMEDORES
  const [nombreComedores, setNombreComedores] = useState("");
  const [activoComedores, setActivoComedores] = useState(false);
  const [proyectoComedores, setProyectoComedores] = useState(null);

  // LECTORES
  const [nombreLectores, setNombreLectores] = useState("");
  const [comedorLectores, setComedorLectores] = useState(null);
  const [proyectoLectores, setProyectoLectores] = useState(null);
  const [activoLectores, setActivoLectores] = useState(false);

  // COMENSALES
  const [idComensalComensales, setidComensalComensales] = useState(null);
  const [nombreComensales, setNombreComensales] = useState("");
  const [tipoDocumentoComensales, setTipoDocumentoComensales] = useState("");
  const [numeroDocumentoComensales, setNumeroDocumentoComensales] =
    useState(null);
  const [tipoCargoComensales, setTipoCargoComensales] = useState("");
  const [cargoComensales, setCargoComensales] = useState("");
  const [empresaComensales, setEmpresaComensales] = useState("");
  const [referenciaComensales, setReferenciaComensales] = useState("");
  const [tipoProcedenciaComensales, setTipoProcedenciaComensales] =
    useState("");
  const [comedorComensales, setComedorComensales] = useState(null);
  const [clienteComensales, setClienteComensales] = useState(null);
  const [proyectoComensales, setProyectoComensales] = useState(null);
  const [desayunoComensales, setDesayunoComensales] = useState(false);
  const [almuerzoComensales, setAlmuerzoComensales] = useState(false);
  const [cenaComensales, setCenaComensales] = useState(false);

  useEffect(() => {
    if (dataSelected && dataSelected.newSelection) {
      switch (data[3]) {
        case "postProyectos":
          editProyectos();
          break;
        case "postClientes":
          editClientes();
          break;
        case "postComedores":
          editComedores();
          break;
        case "postLectores":
          editLectores();
          break;
        case "postComensales":
          editComensales();
          break;
        default:
          break;
      }
    }
  }, [dataSelected, data]);

  const editProyectos = () => {
    setNombreProyecto(dataSelected.newSelection.nombre || "");
    setPaisProyecto(dataSelected.newSelection.pais || "");
    setProvinciaProyecto(dataSelected.newSelection.provincia || "");
    setDistritoProyecto(dataSelected.newSelection.distrito || "");
  };
  const editClientes = () => {
    setNombreSociedadCliente(dataSelected.newSelection.nombre_sociedad || "");
    setPrimerNombreCliente(dataSelected.newSelection.primer_nombre || "");
    setSegundoNombreCliente(dataSelected.newSelection.segundo_nombre || "");
    setPrimerApellidoCliente(dataSelected.newSelection.primer_apellido || "");
    setSegundoApellidoCliente(dataSelected.newSelection.segundo_apellido || "");
    setTipoDocumento(dataSelected.newSelection.id_tipo_documento || "");
    setNumeroDocumentoCliente(dataSelected.newSelection.numero_documento || "");
  };
  const editComedores = () => {
    setNombreComedores(dataSelected.newSelection.nombre || "");
    setActivoComedores(dataSelected.newSelection.activo || "");
    setProyectoComedores(dataSelected.newSelection.id_proyecto || "");
  };
  const editLectores = () => {
    setNombreLectores(dataSelected.newSelection.nombre || "");
    setComedorLectores(dataSelected.newSelection.id_comedor || "");
    setProyectoLectores(dataSelected.newSelection.id_proyecto || "");
    setActivoLectores(dataSelected.newSelection.activo || "");
  };
  const editComensales = () => {
    setidComensalComensales(dataSelected.newSelection.id_creado_comensal || "");
    setNombreComensales(dataSelected.newSelection.nombre || "");
    setTipoDocumentoComensales(dataSelected.newSelection.tipo_documento || "");
    setNumeroDocumentoComensales(
      dataSelected.newSelection.numero_documento || ""
    );
    setTipoCargoComensales(dataSelected.newSelection.tipo_cargo || "");
    setCargoComensales(dataSelected.newSelection.cargo || "");
    setEmpresaComensales(dataSelected.newSelection.empresa || "");
    setReferenciaComensales(dataSelected.newSelection.referencia || "");
    setTipoProcedenciaComensales(
      dataSelected.newSelection.tipo_procedencia || ""
    );
    setComedorComensales(dataSelected.newSelection.id_comedor || "");
    setClienteComensales(dataSelected.newSelection.id_cliente || "");
    setProyectoComensales(dataSelected.newSelection.id_proyecto || "");
    setDesayunoComensales(dataSelected.newSelection.desayuno || "");
    setAlmuerzoComensales(dataSelected.newSelection.almuerzo || "");
    setCenaComensales(dataSelected.newSelection.cena || "");
  };

  if (!isOpen) return null;
  if (!dataSelected) {
    toast.error("Tiene que seleccionar un cliente para editarlo", {
      position: "bottom-right",
      autoClose: 500,
      onClose: () => window.location.reload(),
    });
    return null;
  }

  const handleSubmit = () => {
    let dataSend = null;

    if (data[3] === "postProyectos") {
      dataSend = {
        nombre: nombreProyecto,
        pais: paisProyecto,
        provincia: provinciaProyecto,
        distrito: distritoProyecto,
      };
    } else if (data[3] === "postClientes") {
      dataSend = {
        nombre_sociedad: nombreSociedadCliente,
        primer_nombre: primerNombreCliente,
        segundo_nombre: segundoNombreCliente,
        primer_apellido: primerApellidoCliente,
        segundo_apellido: segundoApellidoCliente,
        id_tipo_documento: tipoDocumento,
        numero_documento: numeroDocumentoCliente,
      };
    } else if (data[3] === "postComedores") {
      dataSend = {
        nombre: nombreComedores,
        activo: activoComedores,
        id_proyecto: proyectoComedores,
      };
    } else if (data[3] === "postLectores") {
      dataSend = {
        nombre: nombreLectores,
        id_proyecto: proyectoLectores,
        id_comedor: comedorLectores,
        activo: activoLectores,
      };
    } else if (data[3] === "postComensales") {
      dataSend = {
        id_creado_comensal: idComensalComensales,
        nombre: nombreComensales,
        tipo_documento: tipoDocumentoComensales,
        numero_documento: numeroDocumentoComensales,
        cargo: cargoComensales,
        tipo_cargo: tipoCargoComensales,
        empresa: empresaComensales,
        referencia: referenciaComensales,
        tipo_procedencia: tipoProcedenciaComensales,
        id_proyecto: proyectoComensales,
        id_comedor: comedorComensales,
        id_cliente: clienteComensales,
        desayuno: desayunoComensales,
        almuerzo: almuerzoComensales,
        cena: cenaComensales,
      };
    }

    put(dataSend, dataSelected.newSelection.id, data[2]).then((res) => {
      if (res && res?.status) {
        if (res?.status === 200) {
          toast.success(res.data.message, {
            position: "bottom-right",
            autoClose: 500,
            onClose: () => window.location.reload(),
          });
        }
      }
    });
  };

  return (
    <div id="modal-mantenimiento">
      <div
        className="container_form"
        style={{ maxHeight: "500px", overflowY: "auto" }}
      >
        <form>
          {data[3] === "postProyectos" ? (
            <>
              <div className="inputs_modal_post">
                <label htmlFor="nombre_proyecto">Nombre del Proyecto *</label>
                <input
                  type="text"
                  value={nombreProyecto}
                  name="nombre"
                  id="nombre_proyecto"
                  placeholder="Ingrese el nombre del proyecto"
                  onChange={(e) => setNombreProyecto(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="pais">Pais del Proyecto</label>
                <input
                  type="text"
                  value={paisProyecto}
                  name="pais"
                  id="pais_proyecto"
                  placeholder="Ingrese el pais del proyecto"
                  onChange={(e) => setPaisProyecto(e.target.value)}
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="provincia">Provincia del Proyecto</label>
                <input
                  type="text"
                  value={provinciaProyecto}
                  name="provincia"
                  id="provincia_proyecto"
                  placeholder="Ingrese la provincia del proyecto"
                  onChange={(e) => setProvinciaProyecto(e.target.value)}
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="distrito_proyecto">Distrito del Proyecto</label>
                <input
                  type="text"
                  value={distritoProyecto}
                  name="distrito"
                  id="distrito_proyecto"
                  placeholder="Ingrese el distrito del proyecto"
                  onChange={(e) => setDistritoProyecto(e.target.value)}
                />
              </div>
            </>
          ) : data[3] === "postClientes" ? (
            <>
              <div className="inputs_modal_post">
                <label htmlFor="nombre_sociedad-cliente">
                  Nombre de sociedad del Cliente *
                </label>
                <input
                  type="text"
                  value={nombreSociedadCliente}
                  name="nombre_sociedad"
                  id="nombre_sociedad-cliente"
                  placeholder="Ingrese el nombre de sociedad del cliente"
                  onChange={(e) => setNombreSociedadCliente(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="primer_nombre-cliente">
                  Primer Nombre del Cliente
                </label>
                <input
                  type="text"
                  value={primerNombreCliente}
                  name="primer_nombre"
                  id="primer_nombre-cliente"
                  placeholder="Ingrese el primer nombre del cliente"
                  onChange={(e) => setPrimerNombreCliente(e.target.value)}
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="segundo_nombre-cliente">
                  Segundo Nombre del Cliente
                </label>
                <input
                  type="text"
                  value={segundoNombreCliente}
                  name="segundo_nombre"
                  id="segundo_nombre-cliente"
                  placeholder="Ingrese el segundo nombre del cliente"
                  onChange={(e) => setSegundoNombreCliente(e.target.value)}
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="primer_apellido-cliente">
                  Primer Apellido del Cliente
                </label>
                <input
                  type="text"
                  value={primerApellidoCliente}
                  name="primer_apellido"
                  id="primer_apellido-cliente"
                  placeholder="Ingrese el segundo apellido del cliente"
                  onChange={(e) => setPrimerApellidoCliente(e.target.value)}
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="segundo_apellido-cliente">
                  Segundo Apellido del Cliente
                </label>
                <input
                  type="text"
                  value={segundoApellidoCliente}
                  name="segundo_apellido"
                  id="segundo_apellido-cliente"
                  placeholder="Ingrese el segundo apellido del cliente"
                  onChange={(e) => setSegundoApellidoCliente(e.target.value)}
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="tipo_documento-cliente">
                  Tipo de Documento del Cliente *
                </label>
                <select
                  id="tipo_documento-cliente"
                  value={tipoDocumento}
                  onChange={(e) => setTipoDocumento(e.target.value)}
                  required
                >
                  <option selected disabled value="">
                    Seleccione el tipo de documento
                  </option>
                  {data[4] &&
                    data[4].map((documento) => (
                      <option key={documento.id} value={documento.id}>
                        {documento.cod_tipo_docu_iden}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="numero_documento-cliente">
                  Numero Documento del Cliente *
                </label>
                <input
                  type="number"
                  value={numeroDocumentoCliente}
                  name="numero_documento"
                  id="numero_documento-cliente"
                  placeholder="Ingrese el numero documento del cliente"
                  onChange={(e) => setNumeroDocumentoCliente(e.target.value)}
                  required
                />
              </div>
            </>
          ) : data[3] === "postComedores" ? (
            <>
              <div className="inputs_modal_post">
                <label htmlFor="nombre-comedor">Nombre del Comedor *</label>
                <input
                  type="text"
                  value={nombreComedores}
                  name="nombre"
                  id="nombre-comedor"
                  placeholder="Ingrese el nombre del comedor"
                  onChange={(e) => setNombreComedores(e.target.value)}
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="tipo_documento-comedor">
                  Proyecto del comedor *
                </label>
                <select
                  id="proyecto-comedor"
                  value={proyectoComedores}
                  onChange={(e) => setProyectoComedores(e.target.value)}
                >
                  <option selected disabled value="">
                    Seleccione el proyecto
                  </option>
                  {data[4] &&
                    data[4].map((proyecto) => (
                      <option key={proyecto.id} value={proyecto.id}>
                        {proyecto.nombre}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="activo-comedor">Activo?</label>
                <input
                  type="checkbox"
                  checked={activoComedores}
                  name="activo"
                  id="activo-comedor"
                  onChange={(e) => setActivoComedores(e.target.checked)}
                />
              </div>
            </>
          ) : data[3] === "postLectores" ? (
            <>
              <div className="inputs_modal_post">
                <label htmlFor="nombre-lector">Nombre del Lector *</label>
                <input
                  type="text"
                  value={nombreLectores}
                  name="nombre"
                  id="nombre-lector"
                  placeholder="Ingrese el nombre del lector"
                  onChange={(e) => setNombreLectores(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="id_proyecto-lector">
                  Proyecto del Lector *
                </label>
                <select
                  id="id_proyecto-lector"
                  value={proyectoLectores}
                  onChange={(e) => setProyectoLectores(e.target.value)}
                  required
                >
                  <option selected disabled value="">
                    Seleccione el Proyecto
                  </option>
                  {data[4] &&
                    data[4].map((proyecto) => (
                      <option key={proyecto.id} value={proyecto.id}>
                        {proyecto.nombre}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="id_comedor-lector">Comedor del Lector *</label>
                <select
                  id="id_comedor-lector"
                  value={comedorLectores}
                  onChange={(e) => setComedorLectores(e.target.value)}
                  required
                >
                  <option selected disabled value="">
                    Seleccione el Lector
                  </option>
                  {data[5] &&
                    data[5].map((comedor) => (
                      <option key={comedor.id} value={comedor.id}>
                        {comedor.nombre}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="activo-lector">Activo?</label>
                <input
                  type="checkbox"
                  checked={activoLectores}
                  name="activo"
                  id="activo-lector"
                  onChange={(e) => setActivoLectores(e.target.checked)}
                />
              </div>
            </>
          ) : data[3] === "postComensales" ? (
            <>
              <div className="inputs_modal_post">
                <label htmlFor="idComensal-comensal">
                  Id Unico del Comensal
                </label>
                <input
                  type="number"
                  value={idComensalComensales}
                  name="idComensal"
                  id="idComensal-comensal"
                  placeholder="Ingrese el Id Unico del comensal"
                  onChange={(e) => setidComensalComensales(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="nombre-comensal">Nombre del Comensal *</label>
                <input
                  type="text"
                  value={nombreComensales}
                  name="nombre"
                  id="nombre-comensal"
                  placeholder="Ingrese el nombre del comensal"
                  onChange={(e) => setNombreComensales(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="id_tipo_documento-comensal">
                  Tipo Documento del Comensal *
                </label>
                <select
                  id="id_tipo_documento-comensal"
                  value={tipoDocumentoComensales}
                  onChange={(e) => setTipoDocumentoComensales(e.target.value)}
                  required
                >
                  <option selected disabled value="">
                    Seleccione el Tipo Documento
                  </option>
                  {data[7] &&
                    data[7].map((tipo_documento) => (
                      <option
                        key={tipo_documento.id}
                        value={tipo_documento.cod_tipo_docu_iden}
                      >
                        {tipo_documento.cod_tipo_docu_iden}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="numero_documento-comensal">
                  Numero Documento del Comensal *
                </label>
                <input
                  type="number"
                  value={numeroDocumentoComensales}
                  name="numero_documento"
                  id="numero_documento-comensal"
                  placeholder="Ingrese el numero documento del comensal"
                  onChange={(e) => setNumeroDocumentoComensales(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="id_tipo_cargo-comensal">
                  Tipo Cargo del Comensal
                </label>
                <select
                  id="id_tipo_cargo-comensal"
                  value={tipoCargoComensales}
                  onChange={(e) => setTipoCargoComensales(e.target.value)}
                  required
                >
                  <option selected disabled value="">
                    Seleccione el Tipo Cargo
                  </option>
                  {data[8] &&
                    data[8].map((tipo_cargo) => (
                      <option key={tipo_cargo.id} value={tipo_cargo.nombre}>
                        {tipo_cargo.nombre}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="cargo-comensal">Cargo del Comensal</label>
                <input
                  type="texto"
                  value={cargoComensales}
                  name="cargo"
                  id="cargo-comensal"
                  placeholder="Ingrese el cargo del comensal"
                  onChange={(e) => setCargoComensales(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="empresa-comensal">Empresa del Comensal</label>
                <input
                  type="texto"
                  value={empresaComensales}
                  name="empresa"
                  id="empresa-comensal"
                  placeholder="Ingrese el empresa del comensal"
                  onChange={(e) => setEmpresaComensales(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="referencia-comensal">
                  Referencia del Comensal
                </label>
                <input
                  type="texto"
                  value={referenciaComensales}
                  name="referencia"
                  id="referencia-comensal"
                  placeholder="Ingrese el referencia del comensal"
                  onChange={(e) => setReferenciaComensales(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="referencia-comensal">Tipo de Procedencia</label>
                <input
                  type="text"
                  value={tipoProcedenciaComensales}
                  name="tipo-precedencia"
                  id="tipo-precedencia-comensal"
                  placeholder="Ingrese el tipo de precedencia del comensal"
                  onChange={(e) => setTipoProcedenciaComensales(e.target.value)}
                  required
                />
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="id_proyecto-comensal">
                  Proyecto del Comensal *
                </label>
                <select
                  id="id_proyecto-comedor"
                  value={proyectoComensales}
                  onChange={(e) => setProyectoComensales(e.target.value)}
                  required
                >
                  <option selected disabled value="">
                    Seleccione el Proyecto
                  </option>
                  {data[4] &&
                    data[4].map((proyecto) => (
                      <option key={proyecto.id} value={proyecto.id}>
                        {proyecto.nombre}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="id_comedor-comedor">
                  Comedor del Comensal *
                </label>
                <select
                  id="id_comedor-comedor"
                  value={comedorComensales}
                  onChange={(e) => setComedorComensales(e.target.value)}
                  required
                >
                  <option selected disabled value="">
                    Seleccione el Comedor
                  </option>
                  {data[5] &&
                    data[5].map((comedor) => (
                      <option key={comedor.id} value={comedor.id}>
                        {comedor.nombre}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="id_cliente-comensal">
                  Cliente del Comensal *
                </label>
                <select
                  id="id_cliente-comensal"
                  value={clienteComensales}
                  onChange={(e) => setClienteComensales(e.target.value)}
                  required
                >
                  <option selected disabled value="">
                    Seleccione el Cliente
                  </option>
                  {data[6] &&
                    data[6].map((cliente) => (
                      <option key={cliente.id} value={cliente.id}>
                        {cliente.nombre_sociedad}
                      </option>
                    ))}
                </select>
              </div>
              <div className="inputs_modal_post">
                <label htmlFor="referencia-comensal">Autorizado para</label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <div
                    className="desayuno"
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <label htmlFor="desayuno-comedor">Desayuno</label>
                    <input
                      type="checkbox"
                      checked={desayunoComensales}
                      name="desayuno"
                      id="desayuno-comedor"
                      onChange={(e) => setDesayunoComensales(e.target.checked)}
                    />
                  </div>
                  <div
                    className="almuerzo"
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <label htmlFor="almuerzo-comedor">Almuerzo</label>
                    <input
                      type="checkbox"
                      checked={almuerzoComensales}
                      name="almuerzo"
                      id="almuerzo-comedor"
                      onChange={(e) => setAlmuerzoComensales(e.target.checked)}
                    />
                  </div>
                  <div
                    className="cena"
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <label htmlFor="cena-comedor">Cena</label>
                    <input
                      type="checkbox"
                      checked={cenaComensales}
                      name="cena"
                      id="cena-comedor"
                      onChange={(e) => setCenaComensales(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </form>
      </div>

      <div
        className="container_buttons_mantenimiento"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <input
          type="submit"
          onClick={handleSubmit}
          className="save-button"
          value="Crear"
        />
        <button
          type="button"
          className="cancel-button"
          onClick={() => onClose(false)}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default ModalPutMantenimientos;

import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  MdAdd,
  MdDeleteOutline,
  MdLogout,
  MdArrowRight,
  MdClear,
  MdEdit,
} from "react-icons/md";
import {
  deleteComedor,
  getComedores,
  getProyectos,
  getUsuariosProyectos,
  postComedores,
  putComedores,
} from "../config/peticionesConToken";
import Header from "../components/Header";
import "../components/css/CargarComensales.css";
import "../components/css/CargarClientes.css";
import { FaCheck, FaNewspaper } from "react-icons/fa";
import ModalPostMantenimientos from "../components/ModalPostMantenimientos";
import ModalPutMantenimientos from "../components/ModalPutMantenimientos";

const CargarComedores = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"));
  const [comedores, setComedores] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [comedorSeleccionado, setComedorSeleccionado] = useState(null);
  const [openModalPost, setOpenModalPost] = useState(false);
  const [openModalPut, setOpenModalPut] = useState(false);

  useEffect(() => {
    if (selectedProject) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    getComedores(selectedProject.id, null, token)
      .then((comedoresBd) => {
        setComedores(Array.isArray(comedoresBd) ? comedoresBd : []);
      })
      .catch((error) => {
        console.error("Error al cargar los datos:", error);
        setComedores([]);
      });

    getUsuariosProyectos(currentUser.id, token)
      .then((userProyectBd) => {
        Promise.all(
          userProyectBd.map((proyect) =>
            getProyectos(proyect.id_proyecto, token)
          )
        )
          .then((results) => {
            const allProyectos = results.flat().filter(Boolean);
            setProyectos(allProyectos);
          })
          .catch((error) => {
            console.error("Error al cargar los datos:", error);
            setProyectos([]);
          });
      })
      .catch((error) => {
        console.error("Error al cargar los datos:", error);
        setProyectos([]);
      });
  };

  const handleSearchClient = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredComedor = comedores.filter((comedor) =>
    comedor.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const eliminarDato = async (id) => {
    const confirmar = window.confirm(
      "¿Estás seguro de que quieres eliminar este dato?"
    );

    if (confirmar) {
      await deleteComedor(id, token);
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  // Handle row selection
  const handleChangeSelectedTr = (index) => {
    const newSelection = comedores[index];
    const trSelected = document.querySelector(`.tr${index}`);
    const trsSelectedBefore = document.querySelector(".selectedTr");

    if (trsSelectedBefore) {
      trsSelectedBefore.classList.remove("selectedTr");
    }
    if (trSelected) {
      if (trsSelectedBefore === trSelected) {
        trsSelectedBefore.classList.remove("selectedTr");
      } else {
        trSelected.classList.add("selectedTr");
      }
    }

    setComedorSeleccionado({ newSelection });
  };

  return (
    <section id="cargar_comensales">
      <Header />
      <ToastContainer />
      <div className="cargar_comensales cargar_clientes">
        <div className="toolbar">
          <button
            onClick={() => setOpenModalPost(true)}
            className="toolbar-button"
          >
            <div className="addNew">
              <FaNewspaper size={24} />
              <MdAdd size={20} color="green" />
            </div>
            Nuevo
          </button>
          <button
            className="toolbar-button"
            onClick={() => setOpenModalPut(true)}
          >
            <MdEdit size={24} />
            Editar
          </button>
          <button
            className="toolbar-button"
            onClick={() => eliminarDato(comedorSeleccionado.newSelection.id)}
          >
            <MdDeleteOutline size={24} />
            Eliminar
          </button>
          <button className="toolbar-button" onClick={() => navigate("/home")}>
            <MdLogout size={24} />
            Cerrar
          </button>
        </div>
        <div className="content">
          <div className="buscar-cliente-container">
            <input
              type="search"
              placeholder="Buscar cliente..."
              value={searchTerm}
              onChange={handleSearchClient}
            />
          </div>
          <table className="dashboard-table">
            <thead>
              <tr>
                <th style={{ width: "5dvh" }}></th>
                <th style={{ textAlign: "center" }}>Nombre</th>
                <th style={{ textAlign: "center" }}>Activo?</th>
              </tr>
            </thead>
            {comedores ? (
              <tbody>
                {(searchTerm ? filteredComedor : comedores).map(
                  (comedor, index) => (
                    <tr key={index} className={`tr${index}`}>
                      <td>
                        <label htmlFor={`checkProyect${index}`}>
                          <MdArrowRight size={24} />
                        </label>
                        <input
                          type="checkbox"
                          className="checkProyect"
                          id={`checkProyect${index}`}
                          onChange={() => handleChangeSelectedTr(index)}
                          value={index}
                        />
                      </td>
                      <td>{comedor.nombre}</td>
                      <td>
                        {comedor.activo ? (
                          <FaCheck color="green" />
                        ) : (
                          <MdClear color="red" fontSize="x-large" />
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    No tiene comedores creados
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        {console.log(proyectos)}
        <ModalPostMantenimientos
          data={[
            selectedProject,
            currentUser,
            token,
            "postComedores",
            proyectos,
          ]}
          isOpen={openModalPost}
          onClose={setOpenModalPost}
          post={postComedores}
        />
        <ModalPutMantenimientos
          data={[
            selectedProject,
            currentUser,
            token,
            "postComedores",
            proyectos,
          ]}
          dataSelected={comedorSeleccionado}
          isOpen={openModalPut}
          onClose={setOpenModalPut}
          put={putComedores}
        />
      </div>
    </section>
  );
};

export default CargarComedores;

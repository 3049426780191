import React, { useState, useEffect } from "react";
import {
  MdLogout,
  MdAdd,
  MdBarcodeReader,
  MdLockOpen,
  MdLock,
  MdDeleteOutline,
  MdArrowRight,
} from "react-icons/md";
import { FaNewspaper } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../components/css/Dashboard.css";
import ModalNew from "../components/ModalNew";
import IngresoModal from "../components/IngresoModal";
import Header from "../components/Header";
import {
  getComedores,
  getClients,
  getTurnoTotal,
  getLectores,
  postTurnoTotal,
  deleteTurnoTotal,
} from "../config/peticionesConToken";
import { getTipoEntrada, getTipoServicio } from "../config/peticionesSinTokens";
import Loader from "../components/Loader";

const Dashboard = () => {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"));

  // State management
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isIngresoModalOpen, setIsIngresoModalOpen] = useState(false);
  const [data, setData] = useState({
    comedores: [],
    tipoServicio: [],
    tipoEntrada: [],
    clientes: [],
    lectores: [],
    turnos: [],
    turnoSeleccionado: null,
  });
  const [openLoader, setOpenLoader] = useState(false);

  // Redirect if not authenticated
  useEffect(() => {
    if (!token || !currentUser) {
      navigate("/");
    }
  }, [navigate, token, currentUser]);

  // Fetch data
  useEffect(() => {
    if (selectedProject) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setOpenLoader(true);
    try {
      const [
        comedoresBd,
        tipoServicioBd,
        tipoEntradaBd,
        clientesBd,
        lectoresBd,
        turnosBd,
      ] = await Promise.all([
        getComedores(selectedProject.id, null, token),
        getTipoServicio(),
        getTipoEntrada(),
        getClients(currentUser.id, null, token),
        getLectores(selectedProject.id, null, null, token),
        getTurnoTotal(
          selectedProject.id,
          null,
          null,
          null,
          null,
          null,
          null,
          token
        ),
      ]);
      setData({
        comedores: comedoresBd,
        tipoServicio: tipoServicioBd,
        tipoEntrada: tipoEntradaBd,
        clientes: clientesBd,
        lectores: lectoresBd,
        turnos: turnosBd,
      });
      localStorage.setItem("tipoServicio", JSON.stringify(tipoServicioBd));
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    if (isIngresoModalOpen && !data.turnoSeleccionado) {
      showToast("error", "Seleccione un turno a modificar", null, null);
      setIsIngresoModalOpen(false);
    }
  }, [isIngresoModalOpen]);

  // Handle row selection
  const handleChangeSelectedTr = (index) => {
    const newSelection = data.turnos[index];
    const trSelected = document.querySelector(`.tr${index}`);
    const trsSelectedBefore = document.querySelector(".selectedTr");

    if (trsSelectedBefore) {
      trsSelectedBefore.classList.remove("selectedTr");
    }
    if (trSelected) {
      if (trsSelectedBefore === trSelected) {
        trsSelectedBefore.classList.remove("selectedTr");
      } else {
        trSelected.classList.add("selectedTr");
      }
    }

    setData((prevData) => ({ ...prevData, turnoSeleccionado: newSelection }));
  };

  // Close ModalNew and possibly reload data
  const handleCloseModalNew = (hasNewTurnTotal = false) => {
    setIsNewModalOpen(false);
    if (hasNewTurnTotal) {
      fetchData();
    }
  };

  // Close IngresoModal and reload the page
  const handleCloseIngresoModal = (isReloadPage = false) => {
    setIsIngresoModalOpen(false);
    if (isReloadPage) {
      window.location.reload();
    }
  };

  const abrirTurno = async () => {
    if (!data.turnoSeleccionado) {
      showToast("error", "Debe seleccionar un turno para abrirlo", null, null);
      return;
    }
    if (data.turnoSeleccionado.cerrado === 0) {
      showToast("success", "Turno ya abierto", null, null);
      return;
    }

    const response = await postTurnoTotal(
      selectedProject.id,
      data.turnoSeleccionado.id_comedor,
      data.turnoSeleccionado.id_tipo_servicio,
      data.turnoSeleccionado.id_lector,
      data.turnoSeleccionado.cantidad_comensales,
      0,
      data.turnoSeleccionado.id,
      token
    );

    if (response.status === 200) {
      setData((prevData) => ({
        ...prevData,
        turnos: prevData.turnos.filter(
          (turno) => turno.id !== data.turnoSeleccionado.id
        ),
        turnoSeleccionado: null,
      }));

      showToast(
        "success",
        "Turno abierto con éxito",
        null,
        window.location.reload()
      );
    } else {
      showToast(
        "error",
        "Error al abrir el turno: " + response.data.message,
        null,
        null
      );
    }
  };

  const cerrarTurno = async () => {
    if (!data.turnoSeleccionado) {
      showToast("error", "Debe seleccionar un turno para cerrarlo", null, null);
      return;
    }
    if (data.turnoSeleccionado.cerrado === 1) {
      showToast("success", "Turno ya cerrado", null, null);
      return;
    }

    const response = await postTurnoTotal(
      selectedProject.id,
      data.turnoSeleccionado.id_comedor,
      data.turnoSeleccionado.id_tipo_servicio,
      data.turnoSeleccionado.id_lector,
      data.turnoSeleccionado.cantidad_comensales,
      1,
      data.turnoSeleccionado.id,
      token
    );

    if (response.status === 200) {
      setData((prevData) => ({
        ...prevData,
        turnos: prevData.turnos.filter(
          (turno) => turno.id !== data.turnoSeleccionado.id
        ),
        turnoSeleccionado: null,
      }));

      showToast(
        "success",
        "Turno cerrado con éxito",
        null,
        window.location.reload()
      );
    } else {
      showToast(
        "error",
        "Error al eliminar el turno: " + response.data.message,
        null,
        null
      );
    }
  };

  const eliminarTurno = async () => {
    if (!data.turnoSeleccionado) {
      showToast("error", "Debe seleccionar un turno para eliminarlo");
      return;
    }

    const confirmDelete = window.confirm(
      "¿Estás seguro de que quieres eliminar este turno?"
    );
    if (!confirmDelete) {
      return;
    }

    if (data.turnoSeleccionado.cantidad_comensales > 0) {
      showToast(
        "error",
        "El turno no debe de tener comensales registrados para eliminarse",
        2000
      );
      return;
    }

    try {
      const response = await deleteTurnoTotal(data.turnoSeleccionado.id, token);

      if (response.status === 200) {
        setData((prevData) => ({
          ...prevData,
          turnos: prevData.turnos.filter(
            (turno) => turno.id !== data.turnoSeleccionado.id
          ),
          turnoSeleccionado: null,
        }));

        showToast(
          "success",
          "Turno cerrado con éxito",
          null,
          window.location.reload()
        );
      } else {
        showToast(
          "error",
          "Error al eliminar el turno: " + response.data.message
        );
      }
    } catch (error) {
      showToast("error", "Error al eliminar el turno: " + error.message);
    }
  };

  const showToast = (type, message, time = 1000) => {
    if (type === "success") {
      toast.success(message, {
        position: "bottom-right",
        autoClose: time,
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "bottom-right",
        autoClose: time,
      });
    }
  };

  return (
    <section id="dashboard">
      <Loader isVisible={openLoader} />
      <ToastContainer />
      <Header />
      <div className="dashboard">
        <div className="toolbar">
          <button
            onClick={() => setIsNewModalOpen(true)}
            className="toolbar-button"
          >
            <div className="addNew">
              <FaNewspaper size={24} />
              <MdAdd size={20} color="green" />
            </div>
            Nuevo
          </button>
          <button
            className="toolbar-button"
            onClick={() => setIsIngresoModalOpen(true)}
          >
            <MdBarcodeReader size={24} />
            Ingresos
          </button>
          <button className="toolbar-button" onClick={() => abrirTurno()}>
            <MdLockOpen size={24} />
            Abrir
          </button>
          <button className="toolbar-button" onClick={() => cerrarTurno()}>
            <MdLock size={24} />
            Cerrar
          </button>
          <button className="toolbar-button" onClick={() => eliminarTurno()}>
            <MdDeleteOutline size={24} />
            Eliminar
          </button>
          <button className="toolbar-button" onClick={() => navigate("/home")}>
            <MdLogout size={24} />
            Cerrar
          </button>
        </div>
        <div className="content">
          <table className="dashboard-table">
            <thead>
              <tr>
                <th></th>
                <th>Proyecto</th>
                <th>Comedor</th>
                <th>Tipo Servicio</th>
                <th>Lectora</th>
                <th>Fecha</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            {data.turnos.length > 0 ? (
              <tbody>
                {data.turnos.map((proyect, index) => (
                  <tr key={index} className={`tr${index}`}>
                    <td>
                      <label htmlFor={`checkProyect${index}`}>
                        <MdArrowRight size={24} />
                      </label>
                      <input
                        type="checkbox"
                        className="checkProyect"
                        id={`checkProyect${index}`}
                        onChange={() => handleChangeSelectedTr(index)}
                        value={index}
                      />
                    </td>
                    <td>
                      {proyect.id_proyecto === selectedProject.id &&
                        selectedProject.nombre}
                    </td>
                    <td>
                      {
                        data.comedores.find(
                          (comedor) => comedor.id === proyect.id_comedor
                        )?.nombre
                      }
                    </td>
                    <td>
                      {
                        data.tipoServicio.find(
                          (servicio) => servicio.id === proyect.id_tipo_servicio
                        )?.nombre
                      }
                    </td>
                    <td>
                      {Array.isArray(data.lectores) && data.lectores.length > 0
                        ? data.lectores.find(
                            (lector) => lector.id === proyect.id_lector
                          )?.nombre
                        : " - "}
                    </td>
                    <td>
                      {proyect.updated_at &&
                        new Date(proyect.updated_at).toLocaleDateString("es")}
                    </td>
                    <td>{proyect.cantidad_comensales}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    No tiene turnos creados
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <ModalNew
          tipoServicio={data.tipoServicio}
          proyecto={selectedProject}
          comedores={data.comedores}
          tipoEntrada={data.tipoEntrada}
          cliente={data.clientes}
          isOpen={isNewModalOpen}
          onClose={handleCloseModalNew}
        />
        {isIngresoModalOpen && data.turnoSeleccionado ? (
          <IngresoModal
            turnoSeleccionado={data.turnoSeleccionado}
            comedores={data.comedores}
            tipoServicio={data.tipoServicio}
            proyectoBD={selectedProject}
            clientes={data.clientes}
            isOpen={isIngresoModalOpen}
            onClose={handleCloseIngresoModal}
            showToast={showToast}
          />
        ) : null}
      </div>
    </section>
  );
};

export default Dashboard;

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import "./css/IngresoModal.css";
import { getComensales, postTurno } from "../config/peticionesConToken";
import { MdClose } from "react-icons/md";
import Loader from "./Loader";

const IngresoModal = ({
  tipoServicio,
  proyectoBD,
  clientes,
  turnoSeleccionado,
  comedores,
  isOpen,
  onClose,
  showToast,
}) => {
  const [dni, setDni] = useState("");
  const [personCount, setPersonCount] = useState(0);
  const [personInfo, setPersonInfo] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [openLoader, setOpenLoader] = useState(false);
  const [registeredDNIs, setRegisteredDNIs] = useState(new Set());
  const dniInputRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dniInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (turnoSeleccionado) {
      setPersonCount(turnoSeleccionado.cantidad_comensales);
      setSelectedDate(turnoSeleccionado.updated_at);
      setRegisteredDNIs(new Set());
    }
  }, [turnoSeleccionado]);

  const comedorActual = useMemo(() => {
    return comedores.find(
      (comedor) => comedor.id === turnoSeleccionado?.id_comedor
    );
  }, [comedores, turnoSeleccionado]);

  const tipoServicioActual = useMemo(() => {
    return tipoServicio.find(
      (servicio) => servicio.id === turnoSeleccionado?.id_tipo_servicio
    );
  }, [tipoServicio, turnoSeleccionado]);

  const handleSubmit = useCallback(async () => {
    setDni("");
    setOpenLoader(true);
    if (registeredDNIs.has(dni)) {
      setPersonInfo({ error: "Comensal ya registrado", color: "red" });
      setOpenLoader(false);
      return;
    }

    try {
      const comensalesRes = await getComensales(
        proyectoBD.id,
        dni,
        null,
        localStorage.getItem("token")
      );
      if (comensalesRes[0]) {
        let data = comensalesRes[0];
        data.message = "Acceso Autorizado";
        data.color = "green";

        setPersonCount(personCount + 1);
        setRegisteredDNIs((prevDNIs) => new Set(prevDNIs).add(dni));

        if (data[tipoServicioActual.nombre.toLowerCase()] !== 1) {
          setPersonInfo({
            error: `Comensal no autorizado para este servicio de ${tipoServicioActual.nombre.toLowerCase()}`,
            color: "red",
          });
          setOpenLoader(false);
          return;
        }

        const tipoServicioValor = tipoServicioActual?.valor_servicio;
        await postTurno(
          proyectoBD.id,
          clientes[0].id,
          turnoSeleccionado.id,
          comedorActual?.id,
          data.id,
          1,
          tipoServicioValor,
          null,
          localStorage.getItem("token")
        )
          .then((res) => {
            res?.response?.data?.error
              ? setPersonInfo({ error: res.response.data.error, color: "red" })
              : setPersonInfo(data);
            setOpenLoader(false);
          })
          .catch((err) => console.error(err));
      } else {
        setPersonInfo({ error: "Comensal no registrado", color: "red" });
        setOpenLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    dni,
    personCount,
    proyectoBD.id,
    clientes.id,
    turnoSeleccionado,
    tipoServicioActual,
    comedorActual,
  ]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  if (!isOpen) return null;

  if (turnoSeleccionado && turnoSeleccionado.cerrado !== 0) {
    onClose(false);
    showToast(
      "error",
      "El turno está cerrado, para abrirlo abra el turno",
      2000
    );
    return null;
  }

  return (
    <div className="ingreso-modal-overlay">
      <Loader isVisible={openLoader} />
      <div
        className="ingreso-modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{ position: "relative" }}
      >
        <MdClose
          id="close-modal-ingreso"
          onClick={onClose}
          style={{ marginBottom: 10, cursor: "pointer" }}
        />
        <div className="ingreso-modal-header">
          <h2>Captura de Atenciones en Comedor</h2>
        </div>

        <div className="container_info_user">
          <div className="info">
            <div className="comedor">
              <label>Comedor</label>
              <input
                type="text"
                disabled
                placeholder={`${
                  turnoSeleccionado.id_comedor &&
                  comedores
                    .filter(
                      (comedor) => comedor.id === turnoSeleccionado.id_comedor
                    )[0]
                    .nombre.toUpperCase()
                }`}
              />
            </div>
            <div className="tipo_servicio">
              <label>Tipo Servicio</label>
              <input
                type="text"
                disabled
                placeholder={`${
                  turnoSeleccionado.id_tipo_servicio &&
                  tipoServicio.filter(
                    (servicio) =>
                      servicio.id === turnoSeleccionado.id_tipo_servicio
                  )[0].nombre
                }`}
              />
            </div>
            <div className="fecha">
              <label>Fecha</label>
              <input
                type="date"
                value={selectedDate}
                disabled
                placeholder={`${new Date(
                  turnoSeleccionado.updated_at
                ).toLocaleDateString("es")}`}
              />
            </div>
          </div>
          <div className="countPersons">
            <p></p>
            {personCount && <p>{personCount}</p>}
            <p></p>
          </div>
        </div>

        <div className="ingreso-modal-body">
          <div className="person-data">
            <input
              type="text"
              value={dni}
              onChange={(e) => setDni(e.target.value)}
              onKeyDown={handleKeyDown}
              className="dni-input"
              ref={dniInputRef}
            />
          </div>
          <div className="container_bar_white">
            <div className="bar_white">
              {personInfo && (
                <p style={{ color: personInfo.color, textAlign: "center" }}>
                  {personInfo.message || personInfo.error}
                </p>
              )}
            </div>
            <div className="bar_white">
              {personInfo && personInfo.nombre && <p>{personInfo.nombre}</p>}
            </div>
            <div className="bar_white">
              {personInfo && personInfo.id_cliente && (
                <p>
                  {clientes &&
                    clientes.id === personInfo.id_cliente &&
                    clientes.nombre_sociedad}
                </p>
              )}
            </div>
            <div className="bar_white"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IngresoModal;

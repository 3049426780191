import React, { useEffect, useState } from "react";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import {
  getClients,
  getComedores,
  getComensales,
  getTurno,
  getTurnoTotal,
} from "../config/peticionesConToken";
import Header from "../components/Header";
import "../components/css/CargarComensales.css";
import "../components/css/ReporteAtencionComedor.css";

const ReporteComensalesComedor = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"));
  const [comedores, setComedores] = useState(null);
  const [clientes, setClientes] = useState(null);
  const [tipoServicio, setTipoServicio] = useState(null);
  const [additionalData, setAdditionalData] = useState({
    id_cliente: "",
    id_proyecto: selectedProject ? selectedProject.id : "",
    id_comedor: "",
  });

  useEffect(() => {
    if (selectedProject) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      const [comedoresBd, clientesBd] = await Promise.all([
        getComedores(selectedProject.id, null, token),
        getClients(currentUser.id, null, token),
      ]);
      setComedores(comedoresBd);
      setClientes(clientesBd.length > 0 ? clientesBd : [clientesBd]);
      setTipoServicio(JSON.parse(localStorage.getItem("tipoServicio")));
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdditionalData({
      ...additionalData,
      [name]: name.includes("fecha")
        ? value
        : value.trim() === ""
        ? undefined
        : parseInt(value),
    });
  };

  const applyStylesToWorkbook = (workbook) => {
    const ws = workbook.Sheets[workbook.SheetNames[0]];

    const alignmentStyle = {
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    const headerStyle = {
      font: { bold: true },
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFFAA00" },
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
      ...alignmentStyle,
    };

    // Aplicar estilos a las celdas
    for (const col in ws) {
      if (col[0] === "!") continue;
      if (!ws[col].s) {
        ws[col].s = {};
      }
      Object.assign(ws[col].s, alignmentStyle);

      if (col.match(/[A-Z]+1$/)) {
        Object.assign(ws[col].s, headerStyle);
      }
    }

    ws["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    applyStylesToWorkbook(wb);
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const transformDataForExport = async (turnos) => {
    const turnosModificados = await Promise.all(
      turnos.map(async (turno) => {
        const comedor =
          comedores &&
          comedores?.filter((comedor) => comedor.id === turno.id_comedor)[0];
        const comensal = await getComensales(
          null,
          null,
          turno.id_comensal,
          token
        );
        await getTurnoTotal(
          null,
          null,
          null,
          null,
          null,
          null,
          turno.id_turno_total,
          token
        );

        return {
          Comedor: comedor.nombre,
          "Identificador Comedor": comedor.id,
          "Nombre Y Apellido": comensal.nombre,
          "Tipo Documento": comensal.tipo_documento,
          "N° Documento Identidad": comensal.numero_documento,
          Cargo: comensal.cargo || "",
        };
      })
    );
    return turnosModificados;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    getTurno(
      additionalData.id_proyecto,
      additionalData.id_cliente,
      null,
      additionalData.id_comedor,
      null,
      null,
      null,
      null,
      token
    )
      .then(async (res) => {
        const turnosParaExportar = await transformDataForExport(res);
        const currentDate = new Date();
        const formattedDate = currentDate
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "");
        const formattedTime = currentDate
          .toTimeString()
          .split(" ")[0]
          .replace(/:/g, "");
        const fileName = `Reporte_Comensales_Por_Comedor_${formattedDate}_${formattedTime}`;
        exportToCSV(turnosParaExportar, fileName);
      })
      .catch((err) => console.error(err));
  };

  return (
    <section id="reporte_atencion_comedor">
      <Header />
      <ToastContainer />
      <div className="cargar_comensales">
        <div className="toolbar">
          <h1>Generar Reporte Comensales Por Comedor</h1>
          <button className="toolbar-button" onClick={() => navigate("/home")}>
            <MdLogout size={24} />
            Cerrar
          </button>
        </div>
        <div className="content" style={{ height: "max-content" }}>
          <form onSubmit={handleSubmit}>
            <label htmlFor="id_proyecto">
              <p>Proyecto</p>
              <input
                type="text"
                name="id_proyecto"
                id="id_proyecto"
                value={selectedProject.nombre}
                placeholder={selectedProject.nombre}
                disabled
              />
            </label>
            <label htmlFor="id_cliente">
              <p>Cliente</p>
              <select
                id="id_cliente"
                name="id_cliente"
                value={additionalData.id_cliente}
                onChange={handleInputChange}
                required
              >
                <option selected disabled value="">
                  Selecciona un cliente
                </option>
                {clientes &&
                  clientes.map((cliente, index) => (
                    <option key={index} value={cliente.id}>
                      {cliente.nombre_sociedad}
                    </option>
                  ))}
              </select>
            </label>
            <label htmlFor="id_comedor">
              <p>Comedor</p>
              <select
                id="id_comedor"
                name="id_comedor"
                value={additionalData.id_comedor}
                onChange={handleInputChange}
                required
              >
                <option selected disabled value="">
                  Selecciona un comedor
                </option>
                {comedores &&
                  comedores.map((comedor, index) => (
                    <option key={index} value={comedor.id}>
                      {comedor.nombre}
                    </option>
                  ))}
              </select>
            </label>
            <button type="submit">Obtener Reporte</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ReporteComensalesComedor;
